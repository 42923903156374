/* eslint-disable import/prefer-default-export */
export const IDR = value => `Rp${new Intl.NumberFormat('id-ID', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format(value)}`

export const IDRWithoutLbl = value => new Intl.NumberFormat('id-ID', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format(value)
